<template>
    <div>
        <div class="header">
            <span class="header_txt">直播推流</span>
        </div>
        <div class="header_box">
            <div class="left">
                <img src="@/assets/live.png" alt="">
                <span class="left_span">选择直播：</span>
                <Select v-model="storePrams.live_id" style="width:200px;margin-right: 20px;" @on-change="liveChange">
                    <Option v-for="item in liveList" :value="item.id" :key="item.id">{{ item.name }}</Option>
                </Select>
            </div>
            <div class="right"></div>
        </div>
        <div class="content">
            <Table :columns="liveTable" :data="dataTable" border>
                <template slot-scope="{ row }" slot="name">
                    <div>{{row.active.name ? row.active.name : ''}}</div>
                </template>
                <template slot-scope="{ row }" slot="cover">
                    <div style="display:flex;align-items:center;height: 56px;justify-content:center">
                        <img v-if="row.active.cover" :src="row.active.cover.path" alt="" style="width: 50px;">
                        <div v-else style="width: 56px;height: 50px;background-color: #f5f7fa;color:#c0c4cc;line-height:50px;text-align:center;">加载失败</div>
                    </div>
                </template>
                <template slot-scope="{ row }" slot="live">
                    <div style="display:flex;align-items:center;height: 56px;justify-content:center">
                        <img v-if="row.active.live" :src="row.active.live.path" alt="" style="width: 50px;">
                        <div v-else style="width: 56px;height: 50px;background-color: #f5f7fa;color:#c0c4cc;line-height:50px;text-align:center;">加载失败</div>
                    </div>
                </template>
                <template slot-scope="{ row }" slot="vest">
                    <div>{{row.vest_num}}</div>
                </template>
                <template slot-scope="{ row, index }" slot="type">
                    <span class="infoModal_text" @click="liveVest(row)">马甲</span>
                    <span class="infoModal_text" @click="recommend(row)">推荐</span>
                    <span v-if="row.explain_status == 0" class="infoModal_text" @click="explain(row)">开始讲解</span>
                    <span v-if="row.explain_status == 1" class="infoModal_text" @click="explainClose(row)">讲解中(关闭讲解)</span>
                </template>
            </Table>
        </div>
        <div class="footer">
            <Page :total="total" show-elevator show-total @on-change="pageChange"/>
        </div>
    </div>
</template>

<script>
import { liveSelect, liveActive, live_recommend_active, live_explain_active, close_explain_active, live_add_vest } from "@/api/index";
    export default {
        data() {
            return {
                liveList: [],
                //table
                liveTable: [
                    {title: '直播活动',slot: 'name',align:'center'},
                    {title: '封面图',slot: 'cover',align:'center'},
                    {title: '直播图片',slot: 'live',align:'center'},
                    {title: '马甲',slot: 'vest',align:'center'},
                    {title: '操作',slot: 'type',align:'center'},
                ],
                dataTable: [],
                //page
                total: 0,
                storePrams: {
                    page: 1,
                    limit: 30,
                    live_id: 1
                },
                textCopy: 'Hello World',
            }
        },
        mounted(){
            this.liveSelect()
            this.liveActive()
        },
        methods: {
             liveActive(){
                liveActive(this.storePrams).then(res => {
                    this.dataTable = res.data.data
                    this.total = res.data.total
                }).catch((res) => {
                    this.$Message.error(res.msg);
                });
            },
            liveSelect(){
                liveSelect().then(res => {
                    this.liveList = res.data
                })
            },
            liveChange(e){
                this.storePrams.live_id = e
                this.liveActive()
            },
            pageChange(index) {
                this.storePrams.page = index;
                this.liveActive()
            },
            liveVest(e){
                let data = {
                    live_id: this.storePrams.live_id,
                    active_id: e.active_id
                }
                live_add_vest(data).then(res => {
                    this.liveActive()
                    this.$Message.success(res.msg)
                }).catch((res) => {
                    this.$Message.error(res.msg);
                });
            },
            recommend(e){
                let data = {
                    live_id: this.storePrams.live_id,
                    active_id: e.active_id
                }
                live_recommend_active(data).then(res => {
                    this.liveActive()
                    this.$Message.success(res.msg)
                }).catch((res) => {
                    this.$Message.error(res.msg);
                });
            },
            explain(e){
                let data = {
                    live_id: this.storePrams.live_id,
                    active_id: e.active_id
                }
                live_explain_active(data).then(res => {
                    this.liveActive()
                    this.$Message.success(res.msg)
                }).catch((res) => {
                    this.$Message.error(res.msg);
                });
            },
            explainClose(e){
                let data = {
                    live_id: this.storePrams.live_id,
                    active_id: e.active_id
                }
                close_explain_active(data).then(res => {
                    this.liveActive()
                    this.$Message.success(res.msg)
                }).catch((res) => {
                    this.$Message.error(res.msg);
                });
            }
        },
    }
</script>

<style lang="scss" scoped>
.header{
    width: 100%;
    height: 55px;
    padding: 16px;
    background-color: #cee0f9;
    border-top-left-radius: 15px; 
    border-top-right-radius: 15px;
    display: flex;
    align-items: center;
}
.header_txt{
    font-size: 20px;
    font-weight: bold;
    color: #000;
}
.header_box{
    margin: 10px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border-radius: 10px;
}
.left{
    display: flex;
    align-items: center;
}
.content{
}
.footer{
    margin: 20px 0;
    text-align: center;
}
.left_span{
    height: 21px;
    color: #374a70;
    font-weight: 700;
    font-size: 16px;
    margin-right: 4px;
    margin-left: 2px;
}
::v-deep .ivu-select-selection{
    height: 40px;
}
::v-deep .ivu-select-selected-value{
    height: 40px !important;
    line-height: 40px !important;
}
.infoModal_text{
    color: #409eff;
    margin: 10px;
    font-size: 12px;
    cursor: pointer;
}
</style>